import React from "react"
import LayoutEng from "../../components/LayoutEng"
import SEO from "../../components/SEO"
import BlogFilterEng from "../../components/BlogFilterEng"

const Blog = () => {
   return (
    <LayoutEng>
      <SEO title="Blog" description="Dive into educational texts that enhance personal wellbeing and mental health. Find guidance and support for navigating life&#39;s important transitions." />
      <main className="page blog-view">
      <div className="Search">
      <h1 style={{ textAlign: `center` }}><strong className="pageNaslov apsihaBlog">Apsiha </strong> Blog</h1>
      <BlogFilterEng />
      </div>
      </main>
    </LayoutEng>
  )
}

export default Blog